<template>
	<div>
		<div v-html="obj">

		</div>
	</div>
</template>

<script>

	export default {
		data() {
			return {
				obj:{}
			}
		},
		created() {
			this.chilelist()
		},
		methods: {
			 chilelist(){
				this.$https
					.get(`/api/pc/yinsi`,)
					.then((res) => {
					console.log(res);
					this.obj=res.data

			
        	})
			 }
		}
	}
</script>

<style scoped>

</style>